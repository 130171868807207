import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import ArticleEditor from 'rev.sdk.js/Components/ArticleEditor';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import queryString from 'query-string';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {message} from 'antd';

function ArticleEditorPage(props) {
  const [user] = useOutlet('user');
  const params = queryString.parse(props.location.search);
  const articleId = params.id;
  const isEditMode = params.edit === 'Y';
  const [article, setArticle] = React.useState(null);
  const app = React.useContext(AppContext.Context);

  React.useEffect(() => {
    const _fetchData = async () => {
      AppActions.setLoading(true);
      try {
        let _article = await app.actions.getArticleById(articleId);
        setArticle(_article);
      } catch (err) {
        //
        message.error('無法取得文章資訊！');
      }
      AppActions.setLoading(false);
    };
    if (!article && articleId) {
      _fetchData();
    }
  }, [articleId]);

  const onUpdate = React.useCallback(() => {
    message.success('文章已更新！');
  });

  if (!article) {
    return null;
  }

  if (!isEditMode) {
    return (
      <Wrapper>
        <div className="container">
          {article.html ? (
            <div dangerouslySetInnerHTML={{__html: article.html}} />
          ) : (
            <RichTextPreview content={article.content || []} />
          )}
        </div>
      </Wrapper>
    );
  }
  return (
    <div style={{padding: 40}}>
      <ArticleEditor
        collection={{
          name: 'Article_Default',
          primary_key: 'id', // currently not support _id.$oid?
        }}
        document={article}
        onUpdate={onUpdate}
        onClose={() => 0}
      />
    </div>
  );
}

const Wrapper = styled.div`
  & > .container {
    margin: 0 auto;
    padding: var(--content-padding);
    max-width: var(--content-max-width);
  }
`;

export default ArticleEditorPage;
